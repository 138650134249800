import { graphType } from "@/api/models/enums/graphType";

type graphItem = {
  ratioBottom?: number;
  ratioTop?: number;
  ratioLeft?: number;
  ratioRight?: number;
  legendWidth?: number;
  legendHeight?: number;
  ref: string;
  name: string;
  customSize?: boolean;
  typeNumber?: boolean;
  allowMaxCategory?: boolean;

  legendWidthWhenTopOrBottom?: number
  legendOffsetWhenTopOrBottom?: number

  graphType?: graphType;
  legendFixed?: boolean;
}
const arr: graphItem[] = [
  {
    name: "(Nombre) Passages infirmerie",
    ref: "nombrePI",
    typeNumber: true,
    graphType : graphType.NUMBER
  },
  {
    name: "(Camembert) Répartition H/F Dossiers avec Passage Infirmerie",
    ref: "dossiers_with_pi_by_sexe_chart",
    ratioBottom: 367.69/650,
    ratioTop: 367.69/650,
    ratioRight: 402.69/650,
    ratioLeft: 402.69/650,
    legendWidth: 200,
    legendWidthWhenTopOrBottom: 550,
    legendOffsetWhenTopOrBottom: 50,
    graphType: graphType.PIE
  },
]
export default arr;
